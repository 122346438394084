<template>
  <div>
    <h1>URG-RH</h1>
    <h2>Login</h2>
    <el-form
      ref="formLogin"
      :model="form"
      :rules="rules"
      :validate-on-rule-change="false"
      :hide-required-asterisk="true"
    >
      <el-form-item label="Email" prop="email">
        <el-input ref="email" v-model="form.email" />
      </el-form-item>
      <el-form-item label="Mot de passe" prop="password">
        <el-input v-model="form.password" show-password />
      </el-form-item>
      <el-button type="primary" native-type="submit" @click.prevent="login"
        >Se connecter</el-button
      >
    </el-form>
    <p>
      <router-link :to="ForgotPwUrl">Mot de passe oublié ?</router-link>
    </p>
  </div>
</template>

<script>
import { getUserError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";
export default {
  name: "LoginView",
  data() {
    const valideEmail = (rule, value, callback) => {
      if (value) {
        const valStr = value.toString().trim();
        if (!valStr) {
          callback(new Error("Ce champ est obligatoire"));
        } else if (
          /^[A-Z0-9a-z._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/.test(valStr)
        ) {
          callback();
        } else {
          callback(new Error("l'adresse email n'est pas valide"));
        }
      } else {
        callback(new Error("Ce champ est obligatoire"));
      }
    };
    return {
      form: {
        email: this.$route.query.email,
        password: null,
      },
      redirect: this.$route.query.redirect,
      error: null,
      rules: {
        email: [{ required: true, validator: valideEmail, trigger: "blur" }],
        password: [
          {
            required: true,
            whitespace: false,
            message: "Ce champ est obligatoire",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.$refs.email.focus();
  },
  computed: {
    ForgotPwUrl() {
      return this.form.email
        ? "/forgot-password?email=" + this.form.email
        : "/forgot-password";
    },
  },
  methods: {
    login() {
      this.$refs.formLogin.validate(async (valid) => {
        if (valid) {
          const payload = this.form;
          this.error = null;
          try {
            await AuthService.login(payload);
            const authUser = await this.$store.dispatch("auth/getAuthUser");
            if (authUser) {
              this.$store.dispatch("auth/setGuest", { value: "isNotGuest" });
              // si pas de redirection, va au Dashboard = table médecin
              this.$router.push(this.redirect ? this.redirect : "/dashboard");
            } else {
              const error = Error(
                "Impossible de récupérer l'utilisateur après la connexion !"
              );
              error.name = "Fetch User";
              throw error;
            }
          } catch (error) {
            this.error = getUserError(error);
          }
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    error() {
      if (this.error) {
        this.$message.error(this.error);
      }
    },
  },
};
</script>
